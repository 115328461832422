export const scopeDiff = (goalScopes:string[], actualScopeStr:string) => {
  let fixedGoalScopes:string[]
  if (goalScopes.includes("classroom.coursework.me.readonly")) { // リクエストするscopeと返ってくるscopeが違うパターンを補正する
    fixedGoalScopes = goalScopes.map(scope => scope === "classroom.coursework.me.readonly" ? "classroom.student-submissions.me.readonly" : scope)
  } else {
    fixedGoalScopes = goalScopes
  }
  const actualScopes = actualScopeStr.split(' ');
  const actualScopesWithoutOpenId = actualScopes.filter(scope => scope !== "openid")
  const difference = fixedGoalScopes.map(scope => `https://www.googleapis.com/auth/${scope}`).filter(x => !actualScopesWithoutOpenId.includes(x));
  return difference
}