import { isCourseWorkHenkyaku } from 'src/components/print/course-work-modal/course-work-modal-helper';
import { HAIFUBUTSU_TOPIC_NAME, KADAI_COLOR, SHUKUDAI_TOPIC_NAME, SIRYOU_COLOR } from 'src/constants';
import { DraggableTabTypes } from 'src/types/file-drop-zone';
import { CourseWork, CourseWorkMaterial, StudentSubmission, Topic } from 'src/types/googleClassroom';

export const toColorName = (type:DraggableTabTypes): string => {
  if(type == DraggableTabTypes.henkyaku || type == DraggableTabTypes.shukudai){
    return KADAI_COLOR
  } else if (type == DraggableTabTypes.haifu) {
    return SIRYOU_COLOR
  } else if (type == DraggableTabTypes.form) {
    return "#6699ff"
  } else {
    return "#56ca70"
  }
}

export const courseWorkToType = (courseWorkOrCourseWorkMaterial: CourseWork | CourseWorkMaterial) => {
  if (isCourseWork(courseWorkOrCourseWorkMaterial)) {
    const courseWork = courseWorkOrCourseWorkMaterial as CourseWork
    if (isCourseWorkHenkyaku(courseWork as any)) {
      return DraggableTabTypes.henkyaku
    }
    const form = courseWork.materials && courseWork.materials[0]?.link
    if (form) {
      return DraggableTabTypes.form
    }
    return DraggableTabTypes.haifu
  } else {
    const courseWorkMaterial = courseWorkOrCourseWorkMaterial as CourseWorkMaterial
    if(courseWorkMaterial.materials) {
      const linkUrl = courseWorkMaterial.materials[0]?.link?.url
      if (linkUrl) {
        return DraggableTabTypes.questionnaire
      }
      if(courseWorkMaterial.materials[0]?.driveFile) {
        return DraggableTabTypes.haifubutsu
      }
    }
  }

  return DraggableTabTypes.henkyaku // デフォルト
}

export const toTopicName = (type:DraggableTabTypes): string => {
  if(type == DraggableTabTypes.henkyaku || type == DraggableTabTypes.form || type == DraggableTabTypes.haifu){
    return SHUKUDAI_TOPIC_NAME
  } else {
    return HAIFUBUTSU_TOPIC_NAME
  }
}

const isCourseWork = (courseWork: CourseWork | CourseWorkMaterial) => {
  const myCourseWork = courseWork as CourseWork
  return !!myCourseWork.workType
}
