import { getTenant } from "src/lib/tenant";

// https://appsco.pe/developer/splash-screens
const AppleTouchStartupImage = () => {
  const tenant = getTenant()
  const path = tenant === "classroomaid" ? "splashscreens-classroomaid" : "splashscreens-printhub"

  return <>
    <link href={`${path}/iphone5_splash.png`} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
    <link href={`${path}/iphone6_splash.png`} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
    <link href={`${path}/iphoneplus_splash.png`} media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
    <link href={`${path}/iphonex_splash.png`} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
    <link href={`${path}/iphonexr_splash.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
    <link href={`${path}/iphonexsmax_splash.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
    <link href={`${path}/ipad_splash.png`} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
    <link href={`${path}/ipadpro1_splash.png`} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
    <link href={`${path}/ipadpro3_splash.png`} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
    <link href={`${path}/ipadpro2_splash.png`} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
  </>
};

export default AppleTouchStartupImage;
