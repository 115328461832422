import { DraggableTabTypes } from "src/types/file-drop-zone";
import { CourseWork } from "../backend/course-work";
import { CourseWorkMaterial } from "../backend/course-work-material";
import { Event, EventData, EventUpdatableProps } from "../event";
import { classroomCourseWorkDueDateToDate } from "../time-converter";
import { courseWorkToType } from "../type-converter";
import { SIRYOU_COLOR } from "src/constants";

export class Shiryou implements EventData {
  courseWorkMaterial: CourseWorkMaterial
  constructor() {
    this.courseWorkMaterial = new CourseWorkMaterial()
  }

  initByBackendData(backendData:any) {
    this.courseWorkMaterial.assignFromCourseWorkMaterialData(backendData)
  }

  id() {
    return this.courseWorkMaterial.id
  }

  idAndCourseId() {
    return {
      id: this.courseWorkMaterial.id,
      courseId: this.courseWorkMaterial.courseId,
    }
  }

  courseWorkOrCourseWorkMaterial() {
    return this.courseWorkMaterial
  }
  // Event#courseWorkToEventBuilderから移行する
  title() {
    return this.courseWorkMaterial.title
  }
  color() {
    return SIRYOU_COLOR // cssのopacityで設定すると中のtextも薄くなるので、rgbaのaで設定する
  }
  backgroundColor() {
    return this.color()
  }
  borderColor() {
    return this.color()
  }
  imgSrc() {
    return this.courseWorkMaterial.imgSrc()
  }
  start() {
    return this.courseWorkMaterial.generateStart()
  }
  end() {
    return this.start()
  }
  type():DraggableTabTypes {
    return courseWorkToType(this.courseWorkMaterial)
  }
  isNew() {
    return false
  }

  editable() {
    return true
  }

  resource() {
    return this.courseWorkMaterial.courseId
  }

  maxPoints() {
    return null
  }

  description() {
    return this.courseWorkMaterial.description
  }

  // init(courseWork: CourseWork) {
  //   this.courseWork = courseWork
  //   this.driveFile = DriveFile.init(this.courseWork)
  // }

  async delete(event: Event) {
    return this.courseWorkMaterial.delete()
  }
  // title() {
  //   this.courseWork.title
  // }
  goDetailUrl(): string {
    return this.courseWorkMaterial.alternateLink
  }

  isPublished() {
    return Boolean(this.courseWorkMaterial.isPublished?.()) 
  }

  mihaifuMessage(): string {
    return "まだ未配布の資料です。配布予定日を過ぎると、Google Classroomのページに移動します。"
  }

  async update(props: EventUpdatableProps) {
    const updatedCourseWorkMaterial = await this.courseWorkMaterial.update(props)
    this.courseWorkMaterial = updatedCourseWorkMaterial
    return this
  }

  attrsEditableOrNot() {
    return {
      title: true,
      description: true,
      start: !this.isPublished(),
      end: false,
      maxPoints: false,
    }
  }

  dataName() {
    return "資料"
  }

  detailExists() {
    return true
  }
}
