import { Course, CourseWorkMaterial as CourseWorkMaterialData, Topic } from "src/types/googleClassroom";
import { Event, EventUpdatableProps, UnsavedEventData } from "../event";
import { toTopicName } from "../type-converter";
import { DraggableTabTypes } from "src/types/file-drop-zone";
import { createCourseWorkMaterial, deleteCourseWorkMaterial, updateCourseWorkMaterial } from "../classroom-api-client";
import { upload } from "../drive-api-client";
import { CourseWorkAndCourseWorkMaterial } from "./course-work-and-course-work-material";
import dayjs from 'dayjs';

// interfaceと同じpropertyの設定を省略できる書き方
// https://stackoverflow.com/a/60047014
// export interface CourseWorkMaterial extends CourseWorkMaterialData { }

export class CourseWorkMaterial extends CourseWorkAndCourseWorkMaterial {
  constructor() {
    super()
  }

  assignFromCourseWorkMaterialData(courseWorkMaterialData:CourseWorkMaterialData) {
    this.alternateLink = courseWorkMaterialData.alternateLink
    this.assigneeMode = courseWorkMaterialData.assigneeMode
    this.courseId = courseWorkMaterialData.courseId
    this.creationTime = courseWorkMaterialData.creationTime
    this.creatorUserId = courseWorkMaterialData.creatorUserId
    this.description = courseWorkMaterialData.description
    this.id = courseWorkMaterialData.id
    this.individualStudentsOptions = courseWorkMaterialData.individualStudentsOptions
    this.materials = courseWorkMaterialData.materials
    this.scheduledTime = courseWorkMaterialData.scheduledTime
    this.state = courseWorkMaterialData.state
    this.title = courseWorkMaterialData.title
    this.topicId = courseWorkMaterialData.topicId
    this.updateTime = courseWorkMaterialData.updateTime
  }

  // これを先にやればいいだけな気がする
  // createCourseWorkMaterialだけしないでおけばよさそう
  async create(eventData: UnsavedEventData, course:Course, topic?: Topic) {
    // const readCheck = event.form.readCheck && event.form.type == DraggableTabTypes.haifubutsu
    const readCheck = null // courseWorkMaterial移行に伴い、とりあえずapiリクエストがエラーにならないように調整
    const start = eventData.start() as Date // event.startにはDateが入っている
    const courseWorkStart = this.scheduledDate(eventData)
    // const topic = event.topics.find((topic) => topic.name == topicName && topic.courseId == course.id)
    const title = eventData.title()
    // google formを添付した場合またはリンクを添付した場合
    const linkUrls = eventData.attachmentLinks()
    const materialFiles = eventData.attachmentFiles()

    let courseWorkRes:CourseWorkMaterialData = await createCourseWorkMaterial({
      scheduledDate: courseWorkStart,
      driveFiles: materialFiles,
      linkUrls: linkUrls,
      courseId: course.id,
      topicId: topic?.topicId,
      title: title,
    })
    // if(event.type == DraggableTabTypes.questionnaire) { // アンケートの場合
    //   courseWorkRes = await createCourseWorkMaterial(courseWorkStart, null, event.form.googleFormUrl, course.id, topic.topicId, title)
    // } else { // 配布物の場合
    //   const res = await upload(event.file as File, course.teacherFolder.id)
    //   courseWorkRes = await createCourseWorkMaterial(courseWorkStart, res.id, null, course.id, topic.topicId, title)
    // }
    Object.assign(this, courseWorkRes); // https://stackoverflow.com/a/40007032
    this.assignFromCourseWorkMaterialData(courseWorkRes)
  }

  async delete() {
    const res: any = await deleteCourseWorkMaterial(this.courseId, this.id);
    return res
  }

  async update(props:EventUpdatableProps) {
    if (props.scheduledTimeDayjs?.isBefore?.(dayjs())) { // 現在を過ぎている場合
      props.scheduledTimeDayjs = null // 存在するとエラーになるので消す
    }
    props.dueDateTimeDayjs = null // 存在するとエラーになるので消す
    const courseWorkMaterialData = await updateCourseWorkMaterial(this.courseId, this.id, props)
    this.assignFromCourseWorkMaterialData(courseWorkMaterialData)
    return this
  }
}

