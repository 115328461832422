import { CourseWorkAndCourseId, Event, EventData, EventUpdatableProps } from "../event";
import { isPast } from 'date-fns'
import { SheetRow } from "../backend/sheet-row";
import { DraggableTabTypes } from "src/types/file-drop-zone";
import { CourseWorkMaterial } from "../backend/course-work-material";
import { CourseWork } from "../backend/course-work";
import { Kadai } from "./kadai";
import { Shiryou } from "./shiryou";
import { parseISO } from 'date-fns'

export class GroupWithSheets implements EventData {
  sheetRow: SheetRow;
  childEventImgSrc: string;
  updatedChildEventDatas: (Kadai | Shiryou)[]
  constructor(groupEventSheetClient:any) {
    this.sheetRow = new SheetRow(groupEventSheetClient)
  }
  
  async create(event: Event) {
  }

  async createGroup(event: Event, courseWorkAndCourseIds: CourseWorkAndCourseId[]) {
    // const sheetRow = new SheetRow(googleSpreadsheetClient)
    // ここではeventにはUnsavedKadai/Shiryouがひもづいている
    await this.sheetRow.create(event, courseWorkAndCourseIds)
  }

  initByBackendData(backendData:any) {
    // this.sheetRow = new SheetRow()
    this.sheetRow.assignFromBackendData(backendData)
  }

  setChildEventImgSrc(courseWorkEvents:Event[]) {
    const courseWorkIds = this.sheetRow.courseWorks.map((courseWork) => courseWork.id)
    const theCourseWorkEvent = courseWorkEvents.find((courseWorkEvent) =>  courseWorkIds.includes(courseWorkEvent.id as string))
    if(theCourseWorkEvent) { // hitしない場合もある
      this.childEventImgSrc = theCourseWorkEvent.imgSrc
    }
  }

  id() {
    return this.sheetRow.id
  }

  // Event#groupEventToEventBuilderから移行する
  title() {
    return this.sheetRow.title
  }
  color() {
    return this.sheetRow.color
  }
  backgroundColor() {
    return this.color()
  }
  borderColor() {
    return this.color()
  }
  imgSrc() {
    return this.childEventImgSrc
  }
  start() {
    return this.sheetRow.start
  }
  end() {
    return this.sheetRow.generateEnd()
  }
  type() {
    return this.sheetRow.type
  }
  isNew() {
    return false
  }

  editable() {
    return this.sheetRow.editable
  }

  resource() {
    return this.sheetRow.resource
  }

  maxPoints() {
    return this.sheetRow.maxPoints
  }

  description() {
    return this.sheetRow.description
  }

  childEventIds() {
    const courseWorks = this.sheetRow.courseWorks
    return courseWorks.map((item) => item.id)
  }

  courseWorks() {
    return this.sheetRow.courseWorks
  }

  async delete(event: Event) {
    return await this.sheetRow.delete()
  }

  isPublished() {
    return Boolean(isPast(parseISO(this.start()))) 
  }

  mihaifuMessage(): string {
    return "まだ未配布です。"
  }


  isCourseWork(): boolean {
    return this.type() === DraggableTabTypes.shukudai
  }

  isCourseWorkMaterial(): boolean {
    return this.type() == DraggableTabTypes.haifu
  }

  async update(props: EventUpdatableProps) {
    const courseWorks = this.courseWorks()
    const updatedChildEventDatas = await Promise.all(courseWorks.map(async (courseWork) => { // グループイベントに紐づくcourseWorkをupdate
      if(this.isCourseWork()){ // CourseWorkの場合
        const courseWorkClass = new CourseWork()
        courseWorkClass.assignFromCourseWorkData({
          courseId: courseWork.courseId,
          id: courseWork.id,
        })
        const updatedCourseWork = await courseWorkClass.update(props)
        const data = new Kadai() // update後にqueryClient.setQueryDataでchildEventsも更新するためresponseからKadaiを作る
        data.initByBackendData(updatedCourseWork)
        return data
      } else if (this.isCourseWorkMaterial()) { // CourseWorkMaterialの場合
        const courseWorkMaterial = new CourseWorkMaterial()
        courseWorkMaterial.assignFromCourseWorkMaterialData({
          courseId: courseWork.courseId,
          id: courseWork.id,
        })
        const updatedCourseWorkMaterial = await courseWorkMaterial.update(props)
        const data = new Shiryou() // update後にqueryClient.setQueryDataでchildEventsも更新するためresponseからShiryouを作る
        data.initByBackendData(updatedCourseWorkMaterial)
        return data
      } 
    }))
    this.updatedChildEventDatas = updatedChildEventDatas // update後にqueryClient.setQueryDataでchildEventsも更新するために持っておく
    const updatedSheetRow = await this.sheetRow.update(props)
    this.sheetRow = updatedSheetRow
    return this
  }

  attrsEditableOrNot() {
    return {
      title: true,
      description: true,
      start: !this.isPublished(),
      end: this.isCourseWork(),
      maxPoints: this.isCourseWork(),
    }
  }

  dataName() {
    return "グループ課題・資料"
  }

  detailExists() {
    return false
  }
}

