import { Course } from "src/types/googleClassroom";
import { CourseWork } from "../backend/course-work";
import { Event, EventData, EventUpdatableProps } from "../event";
import { courseWorkToType } from "../type-converter";
import { DraggableTabTypes } from "src/types/file-drop-zone";
import { KADAI_COLOR } from "src/constants";

export class Kadai implements EventData {
  courseWork: CourseWork
  constructor() {
    this.courseWork = new CourseWork()
  }

  initByBackendData(backendData:any) {
    this.courseWork.assignFromCourseWorkData(backendData)
  }

  id() {
    return this.courseWork.id
  }

  idAndCourseId() {
    return {
      id: this.courseWork.id,
      courseId: this.courseWork.courseId,
    }
  }
  courseWorkOrCourseWorkMaterial() {
    return this.courseWork
  }
  // Event#courseWorkToEventBuilderから移行する
  title() {
    return this.courseWork.title
  }
  color() {
    return KADAI_COLOR // cssのopacityで設定すると中のtextも薄くなるので、rgbaのaで設定する ここ
  }
  backgroundColor() {
    return this.color()
  }
  borderColor() {
    return this.color()
  }
  imgSrc() {
    return this.courseWork.imgSrc()
  }
  start() {
    return this.courseWork.generateStart()
  }
  end() {
    return this.courseWork.generateEnd()
  }
  type():DraggableTabTypes {
    return courseWorkToType(this.courseWork)
  }
  isNew() {
    return false
  }

  editable() {
    return true
  }

  resource() {
    return this.courseWork.courseId
  }

  maxPoints() {
    return this.courseWork.maxPoints
  }

  description() {
    return this.courseWork.description
  }
  // file/formからinitするメソッドと、courseWorkからinitするメソッドを作っておけばOK
  // 全種類のevent-dataクラスに対してbackend側からinitかけてできたeventをeventstoreに放り込めばbackend側からの取得きれいにかけそう
  // それかeventDataFactory的なのを作った方がわかりやすいかも
  find(courseWork: CourseWork) {
    this.courseWork = courseWork
  }

  async delete(event: Event) {
    return this.courseWork.delete()
  }

  isReadCheck() {
    return this.courseWork && this.courseWork.workType == "MULTIPLE_CHOICE_QUESTION" && this.courseWork.multipleChoiceQuestion.choices[0] == "読みました"
  }
  // title() {
  //   this.courseWork.title
  // }

  goDetailUrl(): string { // google formの場合はサムネイルがとれないので、詳細ボタンでclassroomのページに遷移させる
    if(this.courseWork.materials?.[0].form){
      return this.courseWork.alternateLink
    } else {
      return null
    }
  }

  isPublished() {
    return Boolean(this.courseWork.isPublished?.()) 
  }

  mihaifuMessage(): string {
    return "まだ未配布の課題です。配布予定日を過ぎると、生徒の提出物のサムネイルが表示されます。"
  }

  async update(props: EventUpdatableProps) {
    const updatedCourseWork = await this.courseWork.update(props)
    this.courseWork = updatedCourseWork
    return this
  }

  attrsEditableOrNot() {
    return {
      title: true,
      description: true,
      start: !this.isPublished(),
      end: true,
      maxPoints: true,
    }
  }

  dataName() {
    return "課題"
  }

  detailExists() {
    return true
  }
}

