import { CourseWork, CourseWorkMaterial } from "src/types/googleClassroom"
import { EventData } from "../event"
import { Kadai } from "../event-data/kadai"
import { EventDataCreator, CourseWorkLikeEventListFactory } from "./course-work-like-event-list-factory"
import { Henkyaku } from "../event-data/henkyaku"
import { isCourseWorkHenkyaku } from "src/components/print/course-work-modal/course-work-modal-helper"

export class CourseWorkEventListFactory extends CourseWorkLikeEventListFactory implements EventDataCreator {
  constructor() {
    super()
  }

  createEventData(courseWork:CourseWork | CourseWorkMaterial) {
    let data: EventData
    if (isCourseWorkHenkyaku(courseWork as any)) {
      data = new Henkyaku()
    } else {
      data = new Kadai()
    }
    data.initByBackendData(courseWork)
    return data
  }

}
