import { CourseWork, CourseWorkMaterial } from "src/types/googleClassroom"
import { EventData, Event, UserContextData } from "../event"
import { BatchRequests } from "../classroom-api-client"

export interface EventDataCreator {
  createEventData: (courseWork:CourseWork | CourseWorkMaterial) => EventData
}

export abstract class CourseWorkLikeEventListFactory {
  constructor() {
  }
  abstract createEventData(courseWork:CourseWork | CourseWorkMaterial): EventData;

  courseWorksToEvents(courseWorks:CourseWork[] | CourseWorkMaterial[]) {
    const events = courseWorks.map(courseWork => {
      const data = this.createEventData(courseWork)
      const event = new Event(data);
      return event
    })
    return events
  }

  async courseWorkRequestsToCourseWorks(courseWorkRequests:BatchRequests[]) {
    const courseWorksArrayOfArray:CourseWork[][] | CourseWorkMaterial[][] = await Promise.all(courseWorkRequests.flatMap(async (obj) => {
      const response = await obj.request.then()
      const courseWorks:CourseWorkMaterial[] |  CourseWork[] = response.result.courseWorkMaterial ? response.result.courseWorkMaterial : response.result.courseWork
      return courseWorks || []
    }))
    const courseWorks:CourseWork[] | CourseWorkMaterial[] = courseWorksArrayOfArray.flat()
    return courseWorks
  }

  async list(courseWorkRequests:BatchRequests[]) {
    const courseWorks:CourseWork[] = await this.courseWorkRequestsToCourseWorks(courseWorkRequests) as Event[]
    const courseWorkEvents:Event[] = await this.courseWorksToEvents(courseWorks) as Event[]
    return courseWorkEvents
  }

}
