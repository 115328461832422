import dayjs, { Dayjs } from 'dayjs';
import { Date as ClassroomDate, TimeOfDay } from 'src/types/googleClassroom'

export const toTimestamp = (date:Date): string => {
  // "2014-10-02T15:01:23Z"
  const yyyy = date.getUTCFullYear()
  const mm = padding2digits(date.getUTCMonth()+1)
  const dd = padding2digits(date.getUTCDate())
  const hh = padding2digits(date.getUTCHours())
  const mi = padding2digits(date.getUTCMinutes())

  return `${yyyy}-${mm}-${dd}T${hh}:${mi}:00Z`
};

const padding2digits = (number: number): string => {
  var ret = ( '00' + number ).slice( -2 );
  return ret
}

export const scheduledDateToDueDate = (scheduledDate:Date): Date => {
  scheduledDate.setDate(scheduledDate.getDate() + 1);
  return scheduledDate
}

export const mmddWithDoW = (date:Date) => {
  if (!date) {
    return ""
  }
  const day = dayjs(date)
  return `${day.format('M月D日(dd)')}`
}

export const yyyymmddOnUTC = (date:Date) => {
  if (!date) {
    return ""
  }
  const yyyy = date.getUTCFullYear()
  const mm = date.getUTCMonth()+1
  const dd = date.getUTCDate()
  return `${yyyy}/${mm}/${dd}`
}

export const yyyymmddOnLocal = (date:Date) => {
  if (!date) {
    return ""
  }
  const yyyy = date.getFullYear()
  const mm = date.getMonth()+1
  const dd = date.getDate()
  return `${yyyy}/${mm}/${dd}`
}

export const mergeDateWithDayjsTime = (date:Date, time:dayjs.Dayjs): Date => {
  if (!time.isValid()) {
    throw new Error('time is invalid');
  }
  date.setHours(time.hour())
  date.setMinutes(time.minute())
  date.setSeconds(time.second())
  return date
}

export const classroomCourseWorkDueDateToDate = (dueDate:ClassroomDate, dueTime:TimeOfDay): Date => {
  if(!(dueDate && dueTime)) {
    return null 
  }
  const date = new Date()
  if(dueDate.year && dueDate.month && dueDate.day){
    date.setUTCFullYear(dueDate.year, dueDate.month-1, dueDate.day)
  }
  if(dueTime.hours) {
    date.setUTCHours(dueTime.hours)
  }
  if(dueTime.minutes) {
    date.setUTCMinutes(dueTime.minutes)
  } else { // dueTime.minutesを0に設定すると、responseではdueTime.minutesがなくなるので拾っておかないとminutesが未設定=現在時刻のmmが採用されてしまう
    date.setUTCMinutes(0)
  }
  if(dueTime.seconds) {
    date.setUTCSeconds(dueTime.seconds)
  } else { // dueTime.secondsを0に設定すると、responseではdueTime.secondsがなくなるので拾っておかないとminutesが未設定=現在時刻のssが採用されてしまう
    date.setUTCSeconds(0)
  }
  return date
}

  // 次の日を返す、次の日が土日なら月曜を返す
  export const getNextDateOrMonday = (date: Date) => {
    const day = date.getDay()
    const nextDate = new Date(date.getTime())
    if(day === 5 || day === 6) {
      return new Date(nextDate.setDate(nextDate.getDate() + (day === 5 ? 3 : 2)))
    } else {
      return new Date(nextDate.setDate(nextDate.getDate() + 1))
    }
  }

  export const getNextDay = (date: Date) => {
    const nextDate = new Date(date.getTime())
    return new Date(nextDate.setDate(nextDate.getDate() + 1))
  }

  export const getPrevDay = (date: Date) => {
    const nextDate = new Date(date.getTime())
    return new Date(nextDate.setDate(nextDate.getDate() - 1))
  }

  export const isToday = (someDate:Date) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  export const createOneYearOfyyyymmFrom = (now:Date) => {
    const yyyymms = [...Array(12)].map(() => {
      const monthWithPadding = String(now.getMonth()+1).padStart(2, '0');
      const yyyymm = `${now.getFullYear()}${monthWithPadding}`
      now.setMonth(now.getMonth()+1)
      return yyyymm
    })
    return yyyymms
  }

  export function getWeekdaysInMonth(year: number, month: number): Date[] {
    const weekdays: Date[] = [];
    const daysInMonth = new Date(year, month, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        weekdays.push(date);
      }
    }
    return weekdays;
  }
  
export const checkIfSameDate = (date1:Date, date2:Date) => {
  return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate()
}

export const isExpired = (unixTime:number) => {
  let currentUnixTime = Math.floor(Date.now() / 1000);
  return unixTime < currentUnixTime
}