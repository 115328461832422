import { GoogleSpreadsheet, GoogleSpreadsheetWorksheet } from 'google-spreadsheet';
import { NodeModel } from 'src/components/tree-view/node-model';
import { NodeModel as NodeModelType } from "src/components/tree-view/types";

export const SheetType = {
  groupEvents: {
    sheetTitle: "groupEvents",
    headerValues: ["id","title","description","resource","courseWorks","start","end","type","maxPoints"]
  },
  nodeModels: {
    sheetTitle: "nodeModels",
    headerValues: ["id","droppable","index","parent","text"]
  }
}

export class GoogleSpreadsheetClient {
  doc?: GoogleSpreadsheet
  groupEventsSheet?: GoogleSpreadsheetWorksheet
  nodeModelsSheet?: GoogleSpreadsheetWorksheet
  constructor() {
  }

  // https://zenn.dev/katoaki/articles/2f23ec42de7f6b
  public static async build(spreadSheetId: string, token: string) {
    const client = new GoogleSpreadsheetClient()
    client.doc = await this.load(spreadSheetId, token)
    client.groupEventsSheet = await GoogleSpreadsheetClient.findOfCreateSheet(SheetType.groupEvents, client.doc)
    client.nodeModelsSheet = await GoogleSpreadsheetClient.findOfCreateSheet(SheetType.nodeModels, client.doc)
    return client
  }

  private static async findOfCreateSheet(sheetType: typeof SheetType.groupEvents, doc: GoogleSpreadsheet) {
    const sheet = doc.sheetsByTitle[sheetType.sheetTitle]
    if (sheet) {
      return sheet
    } else {
      // sheetTitleのシートを作成
      const newSheet = await doc.addSheet({ title: sheetType.sheetTitle, headerValues: sheetType.headerValues  });
      return newSheet
    }
  }
  private static async load(sheetId: string, token: string){
    const doc = new GoogleSpreadsheet(sheetId, { token: token });
    await doc.loadInfo(); // loads document properties and worksheets
    return doc
  }

  async groupEvents() {
    return this.groupEventsSheet.getRows()
  }

  async nodeModels() {
    const rows = await this.nodeModelsSheet.getRows()
    const nodeModels: NodeModel[] = []
    // const rows = await this.groupEventSheetClient.rows()
    rows.forEach((row) => {
      // doc.data() is never undefined for query doc snapshots
      const nodeModel = new NodeModel(row)
      nodeModels.push(nodeModel)
    });
    return nodeModels
  }

  // ループして使った場合に上書きされないかどうか未確認なので注意
  async addNodeModel(nodeModel: NodeModelType) {
    const newRow = await this.nodeModelsSheet.addRow({
      id: nodeModel.id,
      droppable: nodeModel.droppable,
      index: nodeModel.index,
      parent: nodeModel.parent,
      text: nodeModel.text,
    },{
      insert: true,
    })
    return newRow
  }

  async addNodeModels(nodeModels: NodeModelType[]) {
    const newRows = await this.nodeModelsSheet.addRows(nodeModels,{
      insert: true,
    })
    return newRows
  }

  async clearAllNodeModels() {
    await this.nodeModelsSheet.clearRows()
    return true
  }
}

