import { GoogleSpreadsheetRow } from "google-spreadsheet";
import { Course } from "src/types/googleClassroom";

// NodeModelをクラスにして、rowでspreadsheetのrowを持つようにして、作成・更新・削除メソッドを生やす
export class NodeModel {
  id: number;
  parent: number;
  droppable?: boolean;
  text: string;
  data?: any;
  index?: number;
  row?: GoogleSpreadsheetRow<Record<string, any>>;

  constructor(row?:GoogleSpreadsheetRow<Record<string, any>>, course?:Course, itemCopyFrom?:NodeModel) {
    if (itemCopyFrom) { // 既存nodeModelからコピーしたい場合
      this.id = itemCopyFrom.id
      this.parent = itemCopyFrom.parent
      this.droppable = itemCopyFrom.droppable
      this.text = itemCopyFrom.text
      this.data = itemCopyFrom.data
      this.index = itemCopyFrom.index
      this.row = itemCopyFrom.row
    } else {
      this.row = row
      this.id = course && Number(course.id) || Number(row.get('id'))
      this.parent = (row && Number(row.get('parent'))) ?? 0
      this.text = course && course.name || row.get('text')
      if (row) {
        const droppableStr = row.get('droppable') // spreadsheet上はbooleanでもstringとして取得される
        this.droppable = droppableStr && droppableStr.toLowerCase() === "true"        
      } else { // courseはfalse
        this.droppable = false
      }
      this.index = (row && Number(row.get('index'))) ?? -1 // row && Number(row.get('index')) || -1 だとindex=0のときに-1になってしまうので  
    }
    // validationの時にconstructor通るのでグループinグループ作成されない
    // スプシ側で書き換えても読み込み時にグループ外に出るので、最上位のグループとして振る舞う
    // その後updateがあった時にスプシ側もparentが0になる
    this.disallowRecursiveParent()
  }

  // rowがないパターンではrowを新規作成にしたい
  async delete() {
    return await this.row?.delete()
  }
  // グループ配下にグループを置けないようにする
  disallowRecursiveParent() {
    if (this.parent !== 0 && this.droppable) {
      this.parent = 0 // validationで弾くではなく、ローカルでは強制的に最上位グループとして振る舞わせる
    }
  }

  getRowKV() {
    return {
      id: this.id,
      parent: this.parent,
      droppable: this.droppable,
      text: this.text,
      data: this.data,
      index: this.index,
    }
  }
  async update() {
    this.row?.assign(this.getRowKV())
    return await this.row?.save()
  }

  diffExist(diffToNodeModel:NodeModel) {
    const diffNotExist = diffToNodeModel.id === this.id &&
                          diffToNodeModel.text === this.text &&
                          diffToNodeModel.droppable === this.droppable &&
                          diffToNodeModel.parent === this.parent &&
                          diffToNodeModel.index === this.index
    return !diffNotExist
  }

  diffExistOnlyOnIndexOrParent(diffToNodeModel:NodeModel) {
    try{
      const diffNotExistsOnOthers = diffToNodeModel.id === this.id &&
                                    diffToNodeModel.text === this.text &&
                                    diffToNodeModel.droppable === this.droppable
      if(diffNotExistsOnOthers) {
        return diffToNodeModel.parent !== this.parent || diffToNodeModel.index !== this.index
      }
    } catch (error) { // rowが削除されているケースを想定
      console.error("Error in diffExistOnlyOnIndexOrParent:", error);
    }
    return false
  }
}