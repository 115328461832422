import { CourseWorkMaterial as CourseWorkMaterialData } from "src/types/googleClassroom";
import { UnsavedEventData } from "../event";
// interfaceと同じpropertyの設定を省略できる書き方
// https://stackoverflow.com/a/60047014
export interface CourseWorkAndCourseWorkMaterial extends CourseWorkMaterialData { } // CourseWorkMaterialの属性はCourseWorkのsubsetなので共通クラスの属性はCourseWorkMaterialのものを採用
export class CourseWorkAndCourseWorkMaterial {
  constructor() {
  }

  extractDateStringFromTitle(title:string) {
    if(title) {} else { return ""}
    const result = title.match("[0-9]{4}/[0-9]{1,2}/[0-9]{1,2}")
    if(result) {
      return result[0]
    } else {
      return ""
    }
  }

  // courseWorkでoverrideしている
  generateStart() {
    // https://developers.google.com/classroom/reference/rest/v1/courses.courseWork#CourseWorkState
    if(this.state == "DRAFT") {
      return new Date(this.scheduledTime) // scheduledTimeはPUBLISHEDになると消えてしまう
    }
    return new Date(this.updateTime)
  }

  scheduledDate(eventData: UnsavedEventData): Date {
    const start = eventData.start() as Date // eventData.startにはDateが入っている
    const now = new Date()
    const scheduledDate = (start < now || eventData.publishCourseWorkImmediately) ? null : start
    return scheduledDate
  }

  isPublished():boolean {
    return this.state === "PUBLISHED"
  }

  imgSrc() {
    const imgSrc = this.materials?.[0]?.driveFile ? 
      this.materials?.[0]?.driveFile?.driveFile?.thumbnailUrl : (this.materials?.[0].form ? 
      this.materials?.[0].form?.thumbnailUrl : this.materials?.[0]?.link?.thumbnailUrl)
    return imgSrc
  }
}

