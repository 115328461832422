import { NodeModel } from "src/components/tree-view/node-model";
import { PrinthubFetchedCourse } from "./course-model";

export class NodeModelsBuilder {
  static build(courses: PrinthubFetchedCourse[], nodeModels: NodeModel[]) {
    const courseBasedNodeModels = courses.map((course) => {
      const correspondingNodeModel = nodeModels.find((nodeModel) => String(nodeModel.id) === course.id);
      const mergedNodeModel = new NodeModel(correspondingNodeModel?.row, course);
      // parentがない場合は0にする
      const parentNode = nodeModels.find((nodeModel) => nodeModel.id === mergedNodeModel.parent);
      if (!parentNode) {
        mergedNodeModel.parent = 0 // parentがない場合は表示できなくなってしまうのを避けるため0にする
      }
      return mergedNodeModel;
    });
    const groupNodeModels = nodeModels.filter((nodeModel) => nodeModel.droppable);
    const merged = courseBasedNodeModels.concat(groupNodeModels).sort((a, b) => a.index - b.index);
    return merged;
  }
}
