import { FC, ReactNode } from 'react';
import { getTenant, getTenantDocsAppName } from 'src/lib/tenant';

export const Twitter: FC = () => {
  const tenantName = getTenant()
  const appName = getTenantDocsAppName()

  if (tenantName == "classroomaid") {
    // 2024年のファビコンを極める: 本当に必要なファイルはほぼ6つ
    // https://techracho.bpsinc.jp/hachi8833/2024_02_09/108697
    return <>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.classroom-aid.jp" />
        <meta name="twitter:title" content={appName} />
        <meta name="twitter:description" content={appName} />
        <meta name="twitter:image" content="https://www.classroom-aid.jp/social-media/twitter-image-classroomaid.png" />
    </>
  } else {
    return <>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.printhub.jp" />
        <meta name="twitter:title" content={appName} />
        <meta name="twitter:description" content={appName} />
        <meta name="twitter:image" content="https://www.printhub.jp/social-media/printhub-ogimage.png" />
    </>
  }
}
