
import { Button } from '@mui/material'
import { StepType, TourProvider, useTour } from '@reactour/tour'
import { Dispatch, ReactNode } from 'react'

interface PrinthubTourProviderProps {
  children: ReactNode
}

type ClickProps = {
  setIsOpen: Dispatch<React.SetStateAction<Boolean>>
  setCurrentStep: Dispatch<React.SetStateAction<number>>
  setSteps: Dispatch<React.SetStateAction<StepType[]>>
  setMeta: Dispatch<React.SetStateAction<string>>
  currentStep: number
  steps: StepType[]
  meta: string
}

const tourFinishedString = "tourFinished"
const shouldShowTour = () => {
  return false // 一旦tourを非表示
  // const tourFinished = localStorage.getItem(tourFinishedString)
  // return !Boolean(tourFinished)
}

export const PrinthubTourProvider: React.FC<PrinthubTourProviderProps> = (props) => {
  const { children } = props

  const steps = true ? [
    {
      selector: '.first-step',
      content: 'ここへ配布したいプリントのPDFや画像などをPCからドラッグアンドドロップしてください',
    },
    {
      selector: '.file-drop-zone-image-list-item-0',
      content: 'カレンダーにドラッグアンドドロップしてください',
    },
    {
      selector: '.tour-2',
      content: 'おめでとうございます、最初の配布予約が成功しました！予定の日時にプリントが配布されます。',
    },
    {
      selector: '.tour-3',
      content: '続いて、学年を作成してみましょう。このボタンをクリックします。',
    },
    {
      selector: '.tour-4',
      content: 'このボタンをクリックして、設定メニューを開きます。',
    },
    {
      selector: '.tour-5',
      content: 'ボタンをクリックして、学年を作成します。',
    },
    {
      selector: '.tour-6',
      content: '作成した学年に、クラスをドラッグアンドドロップします。',
    },
    {
      selector: '.tour-7',
      content: 'おめでとうございます、クラスを学年に追加できました！',
    },
    {
      selector: '.tour-8',
      content: 'ここをクリックして、カレンダーページに戻ってみましょう。',
    },
    {
      selector: '.tour-9',
      content: 'おめでとうございます、学年にクラスが追加されていますね！これで学年にドラッグアンドドロップすれば、その中のクラス全てに一括でプリントを配布できます。チュートリアルはこれで終了です、お疲れ様でした！',
    },
  ] : []
  
  const isWithImg = (currentStep) => {
    return currentStep == 0 || currentStep == 1 || currentStep == 6
  }

  function Content(props) {
    const { content, currentStep, setIsOpen } = props
    return (
      <>
        <div>{content}</div>
        { isWithImg(currentStep) && <img src={`/my-static/onboarding/tour${currentStep}.gif`} width={300}></img>}
        <Button onClick={()=>{
          localStorage.setItem(tourFinishedString, "true")
          setIsOpen(false)
        }}>チュートリアルをスキップ</Button>
      </>
    )
  }

  function handleSuccessiveOpen(clickProps: ClickProps) {
    const {currentStep, setIsOpen, setCurrentStep} = clickProps
    if (currentStep == 2) {
      if (shouldShowTour()) {
        setCurrentStep(3)
        setIsOpen(true)
        return true
      }
    } else if(currentStep == 7) {
      if (shouldShowTour()) {
        setCurrentStep(8)
        setIsOpen(true)
        return true
      }
    }
    return false
  }

  const handleClickClose = (clickProps: ClickProps) => {
    const {setIsOpen} = clickProps
    if(!handleSuccessiveOpen(clickProps)) {
      setIsOpen(false)
    }
  }

  const handleClickMask = (clickProps: ClickProps) => {
    const {setIsOpen} = clickProps
    if(!handleSuccessiveOpen(clickProps)) {
      setIsOpen(false)
    }
  }

  return (
    <>
      <TourProvider steps={steps} disableInteraction showNavigation={false} components={{Content}} position={'left'} onClickClose={handleClickClose} onClickMask={handleClickMask}>
        {children}
      </TourProvider>
    </>
  )
}

export const usePrinthubTour = () => {
  const { setIsOpen, setCurrentStep, currentStep } = useTour()

  const open = () => {
    if (shouldShowTour()) {
      setIsOpen(true)  
    }
  }

  const showStep = (i:number) => {
    if (currentStep == (i - 1)) {
      setCurrentStep(i)
      open()
    }
  }

  const showStep1 = () => {
    if (currentStep == 0) {
      open()
    }
  }

  const showStep2 = () => {
    showStep(1)
  }

  const showStep3 = () => {
    showStep(2)
  }

  const showStep5 = () => {
    showStep(4)
  }

  const showStep6 = () => {
    showStep(5)
  }

  const showStep7 = () => {
    showStep(6)
  }

  const showStep8 = () => {
    showStep(7)
  }

  const showStep10 = () => {
    showStep(9)
    if (currentStep == 8) { // 更新される前の値なので8
      localStorage.setItem(tourFinishedString, "true")
    }
  }

  return {
    showStep1,
    showStep2,
    showStep3,
    showStep5,
    showStep6,
    showStep7,
    showStep8,
    showStep10,
  }
}

