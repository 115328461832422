import { Resource } from "src/types/resource";
import { node } from "prop-types";
import { Course } from "src/types/googleClassroom";
import { NodeModel, CustomData } from "../components/tree-view/types";

// [1,4,5]
// =>
//  [{"children": [2, 3], "id": 1, "text": "1年生"}, {"children": [7, 8], "id": 4, "text": "2年生"}, {"children": [], "id": 5, "text": "1年3組"}]
export const idArrayToNodeModelLikeObject = (array:any[], originalArray:NodeModel[]): any => {
  let myObjArray = []
  array.map((id)=>{
    const myObj = {}
    const childrenIds = originalArray.filter((node) => node.parent == id).map((node) => node.id)
    const node = originalArray.find((node) => node.id == id)
    myObj['id'] = id
    myObj['name'] = node.text
    myObj['index'] = node.index // ソート用index
    // return {[id]:childrenIds}
    if(childrenIds.length > 0){ // childrenがない場合は属性自体作成しない
      myObj['children'] = childrenIds
    }
    myObjArray.push(myObj)
  })
  const sorter: (a: any, b: any) => number = (a, b) => {
    const childrenExistsOnA = Boolean(a.children)
    const childrenExistsOnB = Boolean(b.children)
    // グループを常に上にする
    if (childrenExistsOnA) {
      return -1 // Aを上に
    }
    if (!childrenExistsOnA && childrenExistsOnB) { // Bにだけchildrenがある場合
      return 1; // Bを上に
    }

    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();

    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  };
  myObjArray.sort(sorter)
  return myObjArray
}

// childrenの深さが同じレイヤーごとに処理していく
export const nodeModelToResource = (originalArray:NodeModel[]): Resource[] => {
  const firstLayer:any[] = originalArray.filter((node) => node.parent == 0).map((node) => node.id)
  const firstLayerObject = idArrayToNodeModelLikeObject(firstLayer, originalArray)
  let nextLayer:any[] = []
  let currentLayer:any[] 
  nextLayer.push(firstLayerObject)

  while (nextLayer.length > 0) {
    currentLayer = nextLayer
    nextLayer = []
    currentLayer.forEach((objects) => {
      objects.forEach((object) => {
        if(object.children){ // childrenがない場合があるのでチェック
          const convertedObjs = idArrayToNodeModelLikeObject(object.children, originalArray) // {1:[], 3:[], 5:[]}
          const childrenIds = convertedObjs.map((obj)=> obj.children).flat()
          object['children'] = convertedObjs
          if(childrenIds.length > 0) { // childrenがある場合はそれを次の処理対象に加える
            nextLayer.push(convertedObjs)
          } else {
            // 
          }
        }
      })
    })
  }
  return firstLayerObject
}

export const mergeCoursesToNodeModel = (courses:Course[], nodeModels:NodeModel[]) => {
  const existIds = nodeModels.filter(nodeModel => nodeModel.droppable == false).map((nodeModel) => {
    return String(nodeModel.id)
  })
  const nonExistCourses = courses.filter((course) => !existIds.includes(course.id) )
  const mappedCourses:NodeModel[] = nonExistCourses.map((course) => {
    return {id: Number(course.id), text: course.name, droppable: false, parent: 0}
  })
  return nodeModels.concat(mappedCourses) // nodeModelじゃなくてnodeModelClassを返したい
}

