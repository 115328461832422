import { CourseWorkAndCourseId, Event, EventUpdatableProps } from "../event";
import { DraggableTabTypes } from "src/types/file-drop-zone";
import { toColorName } from "../type-converter";
import { GoogleSpreadsheetRow } from 'google-spreadsheet';
import { GoogleSpreadsheetClient } from "../google-spreadsheet-client";

export class SheetRow {
  id
  title
  resource
  courseWorks: {courseId: string, id: string}[]
  start
  end
  maxPoints
  description
  type
  color
  editable
  groupEventSheetClient: GoogleSpreadsheetClient
  row:GoogleSpreadsheetRow<Record<string, any>>
  constructor(groupEventSheetClient:GoogleSpreadsheetClient) {
    this.groupEventSheetClient = groupEventSheetClient
  }

  //   return setDoc(doc(firestore, `/domains/${domain}/groupEvents`, String(event.id)), {
  //   title: title,
  //   resource: event.resource,
  //   courseWorks: courseWorkAndCourseIds,
  //   start: event.start,
  //   end: event.end,
  //   type: event.type, // childEventをつくるときには使ってないのでずれる可能性がある
  // });
  async create(event: Event, courseWorkAndCourseIds: CourseWorkAndCourseId[]) {
    const title = event.title
    // 資料の場合はmaxPointが設定されないようにする(ロジック上スプレッドシートに入っていても問題ないが、まぎらわしいのでちゃんと空にする)
    const maxPoints = event.type === DraggableTabTypes.shukudai ? 100 : null
    const newGroupEventRow = await this.groupEventSheetClient.groupEventsSheet.addRow({
      id: event.id,
      title: title,
      resource: event.resource,
      courseWorks: JSON.stringify(courseWorkAndCourseIds),
      start: event.start,
      end: event.end, // (実際には期限が存在しない)グループ資料の場合もあるが、カレンダー上endが必要なので設定する
      type: event.type,
      maxPoints: maxPoints,
    })
    this.assignFromBackendData(newGroupEventRow)
    return this
  }

  assignFromBackendData(row: GoogleSpreadsheetRow<Record<string, any>>) {
    // https://theoephraim.github.io/node-google-spreadsheet/#/?id=working-with-rows
    const eventType:DraggableTabTypes = row.get('type')
    const color = toColorName(eventType)
    this.id = row.get('id')
    this.title = row.get('title')
    this.resource = row.get('resource')
    this.courseWorks = JSON.parse(row.get('courseWorks'))
    this.start = row.get('start')
    this.end = row.get('end')
    this.type = row.get('type')
    this.color = color
    this.maxPoints = row.get('maxPoints')
    this.description = row.get('description')
    this.row = row
  }

  async delete() {
    return await this.row.delete()
  }

  async update(props: EventUpdatableProps) {
    props.title && this.row.set("title", props.title)
    props.scheduledTimeDayjs && this.row.set("start", props.scheduledTimeDayjs)
    props.dueDateTimeDayjs ? this.row.set("end", props.dueDateTimeDayjs) : this.row.set("end", "") // 期限なしは空文字列とする
    props.maxPoints ? this.row.set("maxPoints", props.maxPoints) : this.row.set("maxPoints", "") // 採点なしは空文字列とする
    props.description && this.row.set("description", props.description)
    await this.row.save()
    this.assignFromBackendData(this.row)
    return this
  }

  generateEnd() {
    if (this.end === "") { // 期限無しを表す空文字列のときは、他のクラスに合わせてnullを返す
      return null
    }
    const endDate = new Date(this.end)
    // グループイベントの場合00:00:00だと配下の個別イベントと違ってなぜかラベルが前日までになってしまうので、1秒進めて配下のイベントに合わせる調整
    if (endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getSeconds() === 0) {
      endDate.setSeconds(1)  
    }
    return endDate
  }
}

