import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import type { FC, ReactNode } from 'react'

interface Progress {
  showProgress: boolean
}

export interface ProgressContextValue {
  progress: Progress
  startProgress: (id:string) => void
  stopProgress: (id:string) => void
}

interface ProgressProviderProps {
  children?: ReactNode
}

const initialProgress: Progress = {
  showProgress: false,
}

export const ProgressContext = createContext<ProgressContextValue>({
  progress: initialProgress,
  startProgress: (id:string) => {},
  stopProgress: (id:string) => {},
})
let progressIds = []
export const ProgressProvider: FC<ProgressProviderProps> = (props) => {
  const { children } = props
  const [progress, setProgress] = useState<Progress>(initialProgress)
  const [progressCounter, setProgressCounter] = useState<number>(0)
  // const [progressIds, setProgressIds] = useState<string[]>([])

  const changeProgress = () => {
    if (progressIds.length > 0) {
      setProgress({showProgress: true})
    } else {
      setProgress({showProgress: false})
    }
  }
  const startProgress = (id:string): void => {
    if (!progressIds.includes(id)) {
      progressIds.push(id)
      // setProgressIds([...progressIds, id])
    }
    changeProgress()
  }

  const stopProgress = (id:string): void => {
    progressIds = progressIds.filter((item) => item != id)
    // setProgressIds(filtered)
    changeProgress()
  }

  useEffect(() => {
    if (progressIds.length > 0) {
      setProgress({showProgress: true})
    } else {
      setProgress({showProgress: false})
    }
  }, [progressIds])

  return (
    <ProgressContext.Provider
      value={{
        progress,
        startProgress,
        stopProgress,
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}

ProgressProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ProgressConsumer = ProgressContext.Consumer
