import { Course, Student } from "src/types/googleClassroom";
import { getCourseList, getMyCourseList } from "./classroom-api-client";

export interface PrinthubFetchedCourse extends Course {
  students?: Student[];
  absenceFormUrl?: string;
}

export interface PrinthubTrimmedCourse {
  studentIds: string[];
  courseId: string
}

// course一覧を取得のみに変更、その後のbatchリクエストで一括取得するため
export const list = async () => {
  const courses: PrinthubFetchedCourse[] = await getMyCourseList()
  if(!courses) { return [] }
  
  return courses
}

export const studentList = async () => {
  const courses: PrinthubFetchedCourse[] = await getCourseList()
  if(!courses) { return [] }
  return courses
}

export const trim = (courses: PrinthubFetchedCourse[]): PrinthubTrimmedCourse[] => {
  return courses.map((course) => {
    return {
      courseId: course.id,
      studentIds: course.students.map((student) => student.userId)
    }
  })
}
