import { Event } from "src/lib/event"
import { GoogleSpreadsheetRow } from "google-spreadsheet"
import { GroupWithSheets } from "../event-data/group-with-sheets"
import { GoogleSpreadsheetClient } from "../google-spreadsheet-client"

export class GroupEventListFactory {
  groupEventSheetClient: GoogleSpreadsheetClient
  constructor(groupEventSheetClient:any) {
    this.groupEventSheetClient = groupEventSheetClient
  }

  // バックエンドにspreadsheetを使う場合
  groupEventsToEventsWithSheets(rows:GoogleSpreadsheetRow<Record<string, any>>[], courseWorkEvents:Event[]) {
    const groupEvents:Event[] = []
    rows.forEach((row) => {
      const data = new GroupWithSheets(this.groupEventSheetClient)
      data.initByBackendData(row)
      data.setChildEventImgSrc(courseWorkEvents)
      const event = new Event(data);
      groupEvents.push(event)
    });
    return groupEvents
  }
  // バックエンドにspreadsheetを使う場合
  async domainToEventsWithSheets(courseWorkEvents: Event[]) {
    const rows = await this.groupEventSheetClient.groupEventsSheet.getRows()
    const groupEvents:Event[] = this.groupEventsToEventsWithSheets(rows, courseWorkEvents)
    return groupEvents
  }

  async list(courseWorkEvents:Event[]) {
    const groupEvents:Event[] = await this.domainToEventsWithSheets(courseWorkEvents) as Event[]
    return groupEvents
  }

}
