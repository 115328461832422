type TenantName = "zettalinx" | "classroomaid" | "default";
 
export function getTenant(): TenantName{
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "zettalinx";
    case "classroomaid":
      return "classroomaid";
    default:
      return "default";
  }
}

export function getTenantColor() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "#1eaa39";
    case "classroomaid":
      return "#E38B23";
    default:
      return "#df503e";  
  }
}

export function getTenantLogoBackgroundColor() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "#1eaa39";
    case "classroomaid":
      return "background.paper";
    default:
      return "background.paper";  
  }
}

export function getTenantBasicAuthId() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "abc";
    case "classroomaid":
      return "classroom";
    default:
      return "sakawa";  
  }
}

export function getTenantBasicAuthPassword() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "printhub";
    case "classroomaid":
      return "aid";
    default:
      return "printhub";  
  }
}

export function isHenkyakuEnable() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return true;
    case "classroomaid":
      return false;
    default:
      return false;  
  }
}

export function getTenantDocsAppName() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "プリントハブ";
    case "classroomaid":
      return "ClassroomAID";
    default:
      return "プリントハブ";  
  }
}

export function getTenantDocsImagePath() {
  const tenantName = process.env.NEXT_PUBLIC_TENANT_NAME
  switch (tenantName) {
    case "zettalinx":
      return "/my-static/docs/printhub";
    case "classroomaid":
      return "/my-static/docs/classroomaid";
    default:
      return "/my-static/docs/printhub";  
  }
}