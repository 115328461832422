import { Course, CourseWork, CourseWorkMaterial, DriveFile, Topic } from "src/types/googleClassroom";
import { DraggableTabTypes, DraggableFormElement } from "src/types/file-drop-zone";
import _ from "lodash"
import { Dayjs } from "dayjs";
import { EventApi } from '@fullcalendar/core'
import { CourseWorkShareMode } from "src/components/print/type-tab/share-mode-select";
import { Kadai } from "./event-data/kadai";
import { Shiryou } from "./event-data/shiryou";
import dayjs from 'dayjs';
import { GoogleSpreadsheetRow } from "google-spreadsheet";
import { GroupWithSheets } from "./event-data/group-with-sheets";

export interface PrinthubFetchedEvent {
  id: string | number; // classroomのcourseWorkのid または firestoreのgroupEventのid
  title: string;
  resource: number | string | Array<number | string>;
  courseWorks?: CourseWork[];
  courseWork?: CourseWork;
  start: Date;
  end: Date;
  type: DraggableTabTypes;
  color: string;
  imgSrc?: string;
  editable?: boolean;
}

export interface PrinthubFetchedCourseWorkEvent extends PrinthubFetchedEvent {
  courseWork: CourseWork;
}

export interface PrinthubFetchedGroupEvent extends PrinthubFetchedEvent {
  courseWorks: CourseWork[];
}

export interface PrinthubDroppedFolder {
  files: File[]
  imgSrc: string
}

export interface PrinthubCreateEvent extends EventApi {
  file?: File | PrinthubDroppedFolder
  imgSrc: string
  form: DraggableFormElement
  publishCourseWorkImmediately: boolean
  type: DraggableTabTypes
}

export interface CourseWorkAndCourseId {
  id: string,
  courseId: string,
}

export interface UserContextData {
  domain:string;
  courses: Course[];
}

export interface EventUpdatableProps {
  title: string
  description: string
  scheduledTimeDayjs: dayjs.Dayjs
  dueDateTimeDayjs: dayjs.Dayjs
  noDueDate: boolean
  maxPoints: number
}

// https://b1san-blog.com/post/ts/ts-class/#%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%95%E3%82%A7%E3%83%BC%E3%82%B9
export interface EventData {
  delete(event: Event):Promise<void>
  initByBackendData(backendData):void
  id(): string
  idAndCourseId?():CourseWorkAndCourseId
  // init():void
  title():string
  color():string
  borderColor():string
  backgroundColor():string
  imgSrc():string
  start():Date
  end():Date
  type():DraggableTabTypes
  isNew():boolean
  form?():DraggableFormElement
  resource():string
  maxPoints():number
  description():string
  courseWorkOrCourseWorkMaterial?():CourseWork | CourseWorkMaterial
  editable():boolean
  childEventIds?():string[]
  isReadCheck?():boolean
  goDetailUrl?():string
  isPublished():boolean
  mihaifuMessage():string
  update(props:EventUpdatableProps):Promise<Kadai | Shiryou | GroupWithSheets>
  attrsEditableOrNot(): { // 編集画面で変更可能かどうか
    title: boolean
    description: boolean
    start: boolean
    end: boolean
    maxPoints: boolean
  }
  dataName():string
  detailExists():boolean
  updatedChildEventDatas?: EventData[]
}
export interface ExtendedDriveFile extends DriveFile {
  mimeType: string
  shareMode: CourseWorkShareMode
  imgSrc: string
  isLink?: boolean
}
export interface UnsavedEventData extends EventData{
  create(course: Course, topic?: Topic):Promise<EventData>
  updateStart(droppedDate:Date, formStartTime:Dayjs): void
  updateEnd(droppedDate:Date, formDueTime:Dayjs): void
  updateResource(fixedResource: string): void
  updatepublishCourseWorkImmediately(publishCourseWorkImmediately: boolean): void
  updateTemporaryId(id: string): void
  updateByEventApi(eventApi: EventApi): void
  topicName(): string
  files: ExtendedDriveFile[]
  publishCourseWorkImmediately:boolean
  setFiles(files: any[]): void
  startTime: Dayjs | null
  dueTime?: Dayjs | null
  origin?: string | null // excalidrawのurlをoriginごとに変える用
  isFreeDraw: boolean
  isShareModeSelectable: boolean
  attachmentLinks(): string[]
  attachmentFiles(): ExtendedDriveFile[]
}

// interfaceと同じpropertyの設定を省略できる書き方
// https://stackoverflow.com/a/60047014
export interface Event extends PrinthubCreateEvent { }
// eventとバックエンドの各データを変換する役割の関数群
export class Event {
  domain: string;
  topics: Topic[];
  context: UserContextData;
  data: EventData;
  unsavedEvent: PrinthubCreateEvent; // backendに保存する前の、mobiscrollが渡してくるevent。createの中で使う。

  // constructor(props: UserContextData) {
  //   const {domain, topics} = props
  //   this.domain = domain
  //   this.topics = topics
  //   this.context = props
  //   this.unsavedEvent = {
  //     file: null,
  //     imgSrc: "",
  //     form: {
  //       startTime: null,
  //       dueTime: null,
  //       // readCheck?: boolean,
  //       type: DraggableTabTypes.haifu,
  //       googleFormUrl: null,
  //       studentCopy: null,
  //     },
  //     publishCourseWorkImmediately: false,
  //     type: DraggableTabTypes.haifu
  //   }
  // }
  constructor(eventData: EventData) {
    this.data = eventData
  }

  async delete() {
    return await this.data.delete(this)    
  }

  idAndCourseId() {
    return this.data?.idAndCourseId?.()
  }

  check() {
  }

  initByData(data:EventData) {
    this.data = data
  }

  // 現状のロジックではEvent.list時にresourcesがまだ取得できてないのでこのロジック使えなかった
  // isResourceHasChild(){
  //   const resourcesWithChildren = this.context.resources.filter((resource) => Boolean(resource.children && resource.children.length > 0))
  //   const resourceIdsWithChildren = resourcesWithChildren.map((resource) => resource.id)
  //   return resourceIdsWithChildren.includes(this.resource)
  // }

  get title() {
    return this.data.title()
  }
  get color(){
    return this.data.color()
  }
  get backgroundColor(){
    return this.data?.backgroundColor?.()
  }
  get borderColor(){
    return this.data?.borderColor?.()
  }
  get imgSrc(){
    return this.data.imgSrc()
  }
  get start(){
    return this.data.start()
  }
  get end(){
    return this.data.end()
  }
  get type(){
    return this.data.type()
  }
  get isNew(){
    return this.data.isNew()
  }
  get form(){
    return this.data.form?.()
  }
  // get publishCourseWorkImmediately(){
  //   return this.data.publishCourseWorkImmediately?.()
  // }
  get resource(){
    return this.data.resource()
  }
  // https://fullcalendar.io/docs/resources-and-events
  get resourceId(){ // fullCalendarではresourceへのひもづけキーはresourceではなくresourceIdなので追加
    return this.data.resource()
  }
  get courseWork(){
    return this.data.courseWorkOrCourseWorkMaterial?.()
  }
  get id(){
    return this.data.id()
  }
  get editable(){
    // if(this.isResourceHasChild()){
    //   return false
    // }
    // return this.data.editable()
    return false // editできるかが決まってないのでとりあえず全部falseにしておく
  }

  childEventIds() {
    return this.data.childEventIds?.()
  }

  async update(props:EventUpdatableProps) {
    const data = await this.data.update(props)
    return new Event(data)
  }

  // // 期限のupdateしかできない仕様を先に修正する必要ある
  // async myUpdate() {
  //   const endAsDate:Date = this.end as Date
  //   try{
  //     const newCourseWork:CourseWork = await updateCourseWork(this.courseWork.courseId, this.courseWork.id, endAsDate, null)
  //     return newCourseWork
  //   } catch (error) {
  //     return null
  //   }
  // }

  // // groupのmemberにあたるcourseWorkを検索して、groupEventの画像を設定する
  // static setImgSrcToGroupEvents(groupEvents: Event[], courseWorkEvents:Event[]) {
  //   groupEvents.forEach((groupEvent) => {
  //     const courseWorkIds = groupEvent.courseWorks.map((courseWork) => courseWork.id)
  //     // memberはすべて同じimgSrcであるはずなので、どれでもいい
  //     const theCourseWorkEvent = courseWorkEvents.find((courseWorkEvent) =>  courseWorkIds.includes(courseWorkEvent.id as string))
  //     if(theCourseWorkEvent) { // hitしない場合もある
  //       // const groupData:Group = groupEvent.data as Group
  //       // groupData.childEventImgSrc = theCourseWorkEvent.imgSrc
  //     }
  //   })
  // }

  // GroupEventの配下にあるCourseWorkEventにフラグを付ける
  // static flagCourseWorkEventsInGroupEvents(groupEvents: Event[], courseWorkEvents:Event[]) {
  //   const childrenIds = groupEvents.flatMap((groupEvent) => groupEvent.courseWorks.map((courseWork)=> courseWork.id ))
  //   const childCourseWorkEvents = courseWorkEvents.filter((courseWorkEvent) => childrenIds.includes(courseWorkEvent.id as string))
  //   childCourseWorkEvents.forEach((courseWorkEvent) => {
  //     courseWorkEvent.editable = false
  //     const groupData:Group = groupEvent.data as Group
  //   })
  // }

  isEventValidForUpdate(newEvent: Event, oldEvent: Event):boolean{
    // 長さ変更かつ配布物の場合はfalse返す(配布物の期限変更は許さない)
    // todo: student-mode
    // if (newEvent.type == DraggableTabTypes.shukudai && isEventEndChanged(newEvent, oldEvent)){
    if (newEvent.type == DraggableTabTypes.haifu && this.isEventEndChanged(newEvent, oldEvent)){
      return true
    } else {
      return false
    }
  }

  isReadCheck() {
    return Boolean(this.data.isReadCheck?.())
  }

  // endが変わった場合だけtrue、移動やstartだけ変わった場合はfalse
  isEventEndChanged(newEvent: Event, oldEvent: Event):boolean{
    const startChanged = newEvent.start != oldEvent.start
    const endChanged = newEvent.end != oldEvent.end
    const onlyEndChanged = !startChanged && endChanged
    const onlyStartChanged = startChanged && !endChanged
    return onlyEndChanged
  }

  removeNull(arr:any[]) {
    return arr.filter(e => e)
  }

  isPublished() {
    return Boolean(this.data.isPublished?.()) 
  }

  mihaifuMessage(): string {
    return this.data.mihaifuMessage()
  }

  isKadai() {
    return this.data instanceof(Kadai)
  }

  isSiryou() {
    return this.data instanceof(Shiryou)
  }

  detailExists() {
    return this.data.detailExists()
  }
}
