import { isGoogleSlides } from "src/lib/alternate-link-checker"
import { CourseWork } from "src/lib/backend/course-work"
import { getStudentSubmissionList } from "src/lib/classroom-api-client"
import { getImageMediaMetadataSize, listByCourseWorkId } from "src/lib/drive-api-client"
import { getThumbnailSize } from "src/lib/slides-api-client"
import { Student, StudentSubmission, StudentSubmissionState } from "src/types/googleClassroom"

export const statusCompare = (state:StudentSubmissionState):number => {
  switch (state) {
    case 'TURNED_IN':
      return 0
    case 'RECLAIMED_BY_STUDENT':
      return 1
    case 'CREATED':
      return 2
    case 'NEW':
      return 3
    case 'SUBMISSION_STATE_UNSPECIFIED':
      return 4
    case 'RETURNED':
      return 5
    default:
      return 0
  }
}

export const isCourseWorkHenkyaku = (courseWork: CourseWork) => {
  return !!courseWork?.description?.includes("返却") && courseWork.state == "DRAFT"
}

export const HENKYAKU_PREPARING_STUDENT_SUBMISSION_ID = "dummy"
// invalidationのためにqueryKeyをidにしたいので、一旦返却を考慮しない(2024/07/11)
export async function fetchStudentSubmissionList({ queryKey }) {
  const [_key, { courseWorkId, courseId }] = queryKey
  // ダミーのstudentSubmissionつくるロジックはここ
  // if (isCourseWorkHenkyaku(courseWork)) { // 返却準備中のcourseWorkの場合
  //   // studentsからダミーのstudentSubmissionsをつくる
  //   const students:Student[] = course.students
  //   const data = await listByCourseWorkId(courseWork.id)
  //   const studentSubmissions:StudentSubmission[] = students.map((student) => {
  //     const driveFile = data.files.find((file) => file.appProperties.studentId === student.userId)
  //     return {
  //       id: HENKYAKU_PREPARING_STUDENT_SUBMISSION_ID, // id見て次に進むロジックがあるので、ダミーで設定しておく
  //       userId: student.userId,
  //       courseId: courseWork.courseId,
  //       courseWorkId: courseWork.id,
  //       assignmentSubmission: {
  //         attachments: [
  //           {
  //             driveFile: { // drive apiから取得するものと微妙に形が違うので変換
  //               thumbnailUrl: driveFile?.thumbnailLink?.replace("=s220", "=s600"),
  //               title: driveFile?.name,
  //               id: driveFile?.id,
  //             }
  //           }
  //         ]
  //       }
  //     }
  //   })
  //   // fetchedStudentSubmissions[0].assignmentSubmission.attachments[0].driveFile
  //   return studentSubmissions
  // } else {
    const studentSubmissions = await getStudentSubmissionList(courseId, courseWorkId)
    // driveのgetFileはバッチリクエストにならなかったので、各itemで個別にgetMimeType行う
    // const responses = await listMimeTypes(studentSubmissions)
    // const studentSubmissionsWithMimeType = mergeMimeTypesToSubmissions(studentSubmissions, responses)
    return studentSubmissions || [] // queryFnがundefined返すとエラーになるので
  // }
}

interface Size {
  width: number
  height: number
}

export async function fetchImageSize({ queryKey }) {
  const [_key, { driveFileToCheckImageSize }] = queryKey
  let size: Size
  if(isGoogleSlides(driveFileToCheckImageSize.alternateLink)){
    // getThumbnail
    size = await getThumbnailSize(driveFileToCheckImageSize.id)
  } else {
    // getFile
    const sizeAndMimeType = await getImageMediaMetadataSize(driveFileToCheckImageSize.id)
    if (sizeAndMimeType.mimeType === "application/pdf" ) {
      // pdfはサイズがとれないので、A4の比率に固定する
      const fixedWidth = 500
      size = {
        width: fixedWidth,
        height: fixedWidth * 1.414,
      }
    } else if(sizeAndMimeType.mimeType === "application/vnd.google-apps.document" ||
              sizeAndMimeType.mimeType === "application/vnd.google-apps.spreadsheet" ||
              sizeAndMimeType.mimeType.includes("video/") ||
              sizeAndMimeType.mimeType === "application/vnd.google-apps.drawing"
            ){
      // ドキュメント、スプシ、図形描画、動画でimageMediaMetadataが取得できないので、実際に画像DLしてチェックする
      // https://stackoverflow.com/a/626505/872176
      // https://qiita.com/generosennin/items/ac7797b2af1baa97886e
      const img = new Image()
      const url = driveFileToCheckImageSize.thumbnailUrl
      img.src = url
      try {
        await img.decode()
        size = {
          width: img.width,
          height: img.height,
        }
      }catch(encodingError){
      }
    } else {
      size = {
        width: sizeAndMimeType.width,
        height: sizeAndMimeType.height,
      }
    }
  }
  return size
}