import { CourseWork, CourseWorkMaterial } from "src/types/googleClassroom"
import { Shiryou } from "../event-data/shiryou"
import { EventDataCreator, CourseWorkLikeEventListFactory } from "./course-work-like-event-list-factory"

export class CourseWorkMaterialEventListFactory extends CourseWorkLikeEventListFactory implements EventDataCreator {
  constructor() {
    super()
  }

  createEventData(courseWork:CourseWork | CourseWorkMaterial) {
    const data = new Shiryou()
    data.initByBackendData(courseWork)
    return data
  }

}
