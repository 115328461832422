import { Course, CourseWork as CourseWorkData, Student, StudentSubmission, Topic } from "src/types/googleClassroom";
import { Event, EventUpdatableProps, UnsavedEventData } from "../event";
import { DraggableTabTypes } from "src/types/file-drop-zone";
import { toTopicName } from "../type-converter";
import { classroomCourseWorkDueDateToDate } from "../time-converter";
import { createCourseWork, createCourseWorkMetadata, deleteCourseWork, getStudentList, getStudentSubmissionList, modifyAttachmentToBatch, updateCourseWork } from "../classroom-api-client";
import * as classroomApiClient from "../classroom-api-client";
import { addFileProperties, addParents, copyToBatch, createEmptyExcalidrawReferenceFile, createPermission, upload } from "../drive-api-client";
import * as driveApiClient from "../drive-api-client";
import { CourseWorkAndCourseWorkMaterial } from "./course-work-and-course-work-material";
import { StudentSubmissionWithStudent, mergeStudentsWithSubmissions } from "src/hooks/use-course-work";
import { PrinthubFetchedCourse } from "../course-model";
import { ModifySubmissionInfo } from "../abc-return-helper";
import { exponentialBackoff } from "../exponential-backoff";
import { generateUuid } from "../uuid";
import { CourseWorkShareMode } from "src/components/print/type-tab/share-mode-select";
import dayjs from 'dayjs';
import { UnsavedKadai } from "../event-data/unsaved-kadai";

type DriveApiClientModule = typeof import("../drive-api-client")
type ClassroomApiClientModule = typeof import("../classroom-api-client")

interface SubmissionsAndFileIdsWithPermissionGranted {
  // request: gapi.client.Request<any>;
  submission: StudentSubmission;
  fileId: any;
}

export interface CourseWork extends CourseWorkData { }
export class CourseWork extends CourseWorkAndCourseWorkMaterial {
  classroomApiClient: ClassroomApiClientModule
  driveApiClient: DriveApiClientModule

  constructor() {
    super()
    this.classroomApiClient = classroomApiClient
    this.driveApiClient = driveApiClient
  }

  assignFromCourseWorkData(courseWorkData:CourseWorkData) {
    this.alternateLink = courseWorkData.alternateLink
    this.assigneeMode = courseWorkData.assigneeMode
    this.assignment = courseWorkData.assignment
    this.associatedWithDeveloper = courseWorkData.associatedWithDeveloper
    this.courseId = courseWorkData.courseId
    this.creationTime = courseWorkData.creationTime
    this.creatorUserId = courseWorkData.creatorUserId
    this.description = courseWorkData.description
    this.dueDate = courseWorkData.dueDate
    this.dueTime = courseWorkData.dueTime
    this.gradeCategory = courseWorkData.gradeCategory
    this.id = courseWorkData.id
    this.individualStudentsOptions = courseWorkData.individualStudentsOptions
    this.materials = courseWorkData.materials
    this.maxPoints = courseWorkData.maxPoints
    this.multipleChoiceQuestion = courseWorkData.multipleChoiceQuestion
    this.scheduledTime = courseWorkData.scheduledTime
    this.state = courseWorkData.state
    this.submissionModificationMode = courseWorkData.submissionModificationMode
    this.title = courseWorkData.title
    this.topicId = courseWorkData.topicId
    this.updateTime = courseWorkData.updateTime
    this.workType = courseWorkData.workType
  }

  // これを先にやればいいだけな気がする
  // createCourseWorkだけしないでおけばよさそう
  // Event#createOneCourseWork
  async create(eventData: UnsavedKadai, course:Course, topic?: Topic) {
    let uniqueKey: string = generateUuid()
    let description:string, shareModeValue:CourseWorkShareMode
    // pickerでpickする前提
    // google formを添付した場合またはリンクを添付した場合
    const linkUrls = eventData.attachmentLinks()
    const materialFiles = eventData.attachmentFiles()
    if (eventData.isFreeDraw) {
      const origin = (eventData as UnsavedEventData)?.origin || "https://www.classroom-aid.jp"
      const drawUrl = `${origin}/draw/new?cwKey=${uniqueKey}` // keyだと予約語なのか取得できなかったのでcwKeyにした
      description = `以下のurlにアクセスしてください。${drawUrl}`
      linkUrls.push(drawUrl)
    }

    const start = eventData.start() as Date // eventData.startにはDateが入っている
    const end = eventData.end() as Date // eventData.endにはDateが入っている
    let due = end // dueをnullにすると期限なしになるが、現状UIでendを未設定にできないので常に期限が入る
    const topicName = eventData.topicName()
    // classroomにcourseWorkをcreate
    const title = eventData.title()

    const readCheck = false
    const now = new Date()
    const scheduledDate = this.scheduledDate(eventData)
    // excalidraw個別リンク配布ステップ2. courseWorkを作成（courseWorkIdが確定）
    let courseWork = await this.classroomApiClient.createCourseWork({
      scheduledDate:scheduledDate,
      driveFiles: materialFiles,
      courseId: course.id,
      topicId: topic?.topicId,
      title: title,
      readCheck: readCheck,
      dueDate: due,
      linkUrls: linkUrls,
      description: description,
    })

    this.assignFromCourseWorkData(courseWork)
  }

  generateStart() {
    if(this.state == "DRAFT") {
      return new Date(this.scheduledTime) // scheduledTimeはPUBLISHEDになると消えてしまう
    }else if(this.state == "PUBLISHED") {
      if (this.dueDate && this.dueTime) {
        const myDueDate = classroomCourseWorkDueDateToDate(this.dueDate, this.dueTime)
        const dueDateInDayjs = dayjs(myDueDate)
        if (dueDateInDayjs.isAfter(dayjs())) { // 配布済みかつdueDateが現在以降の場合は、startを今日として、期限日までのラベルにする
          const now = new Date()
          return now    
        } else { // 配布済みかつdueDateが過去の場合は、期限日1日分のラベルにする
          const year = this.dueDate?.["year"]
          const month = this.dueDate?.["month"]
          const day = this.dueDate?.["day"]
          if (year && month && day) {
            return new Date(`${year}/${month}/${day}`)  
          }    
        }
      }
    } 
    return new Date(this.updateTime)
  }

  generateEnd() {
    return classroomCourseWorkDueDateToDate(this.dueDate, this.dueTime)
  }

  async delete() {
    const res: any = await deleteCourseWork(this.courseId, this.id);
    return res
  }
  // find(courseWork: CourseWork) {
  //   this.courseWork = courseWork
  //   this.driveFile = DriveFile.init(this.courseWork)
  // }

  async update(props:EventUpdatableProps) {
    if (props.scheduledTimeDayjs?.isBefore?.(dayjs())) { // 現在を過ぎている場合
      props.scheduledTimeDayjs = null // 存在するとエラーになるので消す
    }
    const courseWorkData = await updateCourseWork(this.courseId, this.id, props)
    this.assignFromCourseWorkData(courseWorkData)
    return this
  }
}
