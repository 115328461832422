import { NodeModel } from "src/components/tree-view/node-model";
import { NodeModel as NodeModelType } from "src/components/tree-view/types";

export class NodeModelsValidator {
  localNewData: NodeModel[];
  remoteData: NodeModel[];
  localOldData: NodeModel[];

  constructor(localData:NodeModel[], remoteData:NodeModel[], localOldData:NodeModel[]) {
    this.localNewData = localData
    this.remoteData = remoteData
    this.localOldData = localOldData
  }

  allLocalNewIds() {
    return this.localNewData.map((treeData) => treeData.id);
  }

  allRemoteIds() {
    return this.remoteData.map((nodeModel) => nodeModel.id);
  }

  idsMatch() {
    // arrayの同一チェック https://stackoverflow.com/a/14288329
    return this.allLocalNewIds().sort().toString() == this.allRemoteIds().sort().toString();
  }

  findCorrespondingItemFromRemote(localNodeModel:NodeModelType){
    return this.remoteData.find((remoteNodeModel) => remoteNodeModel.id === localNodeModel.id);
  }

  // 更新前のデータがremoteとlocalで全く同じであることをチェック(別windowで設定画面開いてグループ名変更したり階層構造変えたりしているケースで上書きしないようにする)
  localAndRemoteOldDataAreSame() {
    return this.localOldData.every((localOldNodeModel) => {
      const remoteNodeModel = this.findCorrespondingItemFromRemote(localOldNodeModel)
      const diffExist = localOldNodeModel.diffExist(remoteNodeModel);
      return !diffExist
    })
  }

  everyDiffAreOnlyOnIndexOrParent() {
    return this.localNewData.every((localNodeModel) => {
      const remoteNodeModel = this.findCorrespondingItemFromRemote(localNodeModel)
      const diffExist = localNodeModel.diffExist(remoteNodeModel);
      if (diffExist) {
        const result = localNodeModel.diffExistOnlyOnIndexOrParent(remoteNodeModel);
        return result          
      } else { 
        return true // diffがそもそもない場合はvalidation通したいのでtrue(例：移動させたクラスの移動前後の位置より下にあって全く影響受けないnodeがある場合でもvalidationは通したい)
      }
    });
  }
  validateClearAllAndCreate() {
    return this.localAndRemoteOldDataAreSame() && this.idsMatch() && this.everyDiffAreOnlyOnIndexOrParent();
  }

  validateAppend(newNodeModel:NodeModelType) {
    const ItemWithSameId = this.findCorrespondingItemFromRemote(newNodeModel) // 同じidのitemがすでにないかチェック(uniqueness)
    return !ItemWithSameId && this.everyDiffAreOnlyOnIndexOrParent();
  }
}
