
import { Topic } from "src/types/googleClassroom";
import { BatchRequests } from "./classroom-api-client";
import * as CourseModel from 'src/lib/course-model'
import { HAIFUBUTSU_TOPIC_NAME, HENKYAKU_TOPIC_NAME, SHUKUDAI_TOPIC_NAME } from "src/constants";

export interface DifferenceToRequiredTopics {
  courseId: string;
  difference: string[];
}

export const getAllTopics = async (topicsRequests:BatchRequests[]) => {
  const topicsOfTopics:Topic[][] = await Promise.all(topicsRequests.map(async (req:BatchRequests) => {
    const value = await req.request.then()
    return value.result.topic
  }))
  const topics:Topic[] = topicsOfTopics.flat().filter(Boolean) // undefined消し
  return topics
}

export function differenceToRequiredTopics(courses: CourseModel.PrinthubFetchedCourse[], topics: Topic[]):DifferenceToRequiredTopics[] {
  const diffs = courses.map((course) => {
    const topicsOfTheCourse = topics.filter((topic) => {
      return topic?.courseId == course.id
    });
    const names = topicsOfTheCourse.map((topic => topic.name));
    const expectedArray = [HAIFUBUTSU_TOPIC_NAME, SHUKUDAI_TOPIC_NAME, HENKYAKU_TOPIC_NAME]
    let difference = expectedArray.filter(x => !names.includes(x));
    if(difference.length > 0) {
      return {
        courseId: course.id,
        difference: difference
      }
    }
  });
  return diffs.filter(Boolean) // undefined消し
}

