import dayjs, { Dayjs } from 'dayjs';
import { PrintCalendarFormsData } from "src/hooks/use-forms-data"

export enum DraggableTabTypes {
    henkyaku = "henkyaku", // 返却準備中
    haifu = "haifu",
    shukudai = "shukudai",
    haifubutsu = "haifubutsu",
    questionnaire = "questionnaire",
    form = "form",
}

export interface FormTabProps {
  data: PrintCalendarFormsData
  titlesInHistory?: string[]
}

export interface DraggableFormElement {
  startTime: Dayjs | null,
  dueTime?: Dayjs | null,
  readCheck?: boolean,
  type: DraggableTabTypes,
  googleFormUrl?: string,
  studentCopy?: boolean,
}

