// https://mui.com/material-ui/react-select/#filled-and-standard-variants
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export enum CourseWorkShareMode {
  VIEW = "VIEW",
  EDIT = "EDIT",
  STUDENT_COPY = "STUDENT_COPY",
  FREE_DRAW = "FREE_DRAW",
}

interface ShareModeSelectProps {
  shareMode: CourseWorkShareMode
  setShareMode: React.Dispatch<React.SetStateAction<CourseWorkShareMode>>
}

export default function ShareModeSelect(props:ShareModeSelectProps) {
  const { shareMode, setShareMode } = props

  const handleChange = (event: SelectChangeEvent) => {
    setShareMode(event.target.value as CourseWorkShareMode);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="share-mode-select-label"
          id="share-mode-select"
          value={shareMode}
          onChange={handleChange}
          label="ShareMode"
        >
          <MenuItem value={CourseWorkShareMode.VIEW}>生徒がファイルを閲覧できる</MenuItem>
          <MenuItem value={CourseWorkShareMode.EDIT}>生徒がファイルを編集できる</MenuItem>
          <MenuItem value={CourseWorkShareMode.STUDENT_COPY}>各生徒にコピーを作成</MenuItem>
          <MenuItem value={CourseWorkShareMode.FREE_DRAW}>フリードローに添付</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
