import axios from "axios";
import { listWithFileIdsResult } from "./drive-api-client";

// slidesのサムネイルはdrive apiから取得できないためdrive apiの結果に付け加える
export const getThumbnails = async (results:listWithFileIdsResult[]) => {
  const newResults = await Promise.all(results.map(async(result) => {
    if (result.mimeType == "application/vnd.google-apps.presentation") {
      // テンプレートから作成するとpage object idがpじゃなくなる時があるので、まずpage object idを取得
      const pageObjId = await getFirstSlidesPageObjectId(result.id);
      const res = await axios.get(`https://slides.googleapis.com/v1/presentations/${result.id}/pages/${pageObjId}/thumbnail`)
      result.thumbnailLink = res.data.contentUrl
      return result        
    } else {
      return result
    }
  }))
  return newResults
};

export const getPageRevisionId = async (presentationId:string, pageObjectId:string) => {
  const res = await axios.get(`https://slides.googleapis.com/v1/presentations/${presentationId}/pages/${pageObjectId}?fields=revisionId`)
  return res.data?.revisionId        
};

export const getThumbnailSize = async (presentationId:string) => {
  const pageObjId = await getFirstSlidesPageObjectId(presentationId);
  const res = await axios.get(`https://slides.googleapis.com/v1/presentations/${presentationId}/pages/${pageObjId}/thumbnail`)
  return {
    width: res.data.width,
    height: res.data.height,
  }
};

export async function getFirstSlidesPageObjectId(fileId: string) {
  const pageObjRes = await axios.get(`https://slides.googleapis.com/v1/presentations/${fileId}?fields=slides(objectId)`);
  const pageObjId = pageObjRes.data.slides[0].objectId;
  return pageObjId;
}
