import { FC, ReactNode } from 'react';
import { getTenant, getTenantDocsAppName } from 'src/lib/tenant';

export const OG: FC = () => {
  const tenantName = getTenant()
  const appName = getTenantDocsAppName()

  if (tenantName == "classroomaid") {
    // 2024年のファビコンを極める: 本当に必要なファイルはほぼ6つ
    // https://techracho.bpsinc.jp/hachi8833/2024_02_09/108697
    return <>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={appName} />
      <meta property="og:description" content={appName} />
      <meta property="og:site_name" content={appName} />
      <meta property="og:url" content="https://www.classroom-aid.jp" />
      <meta property="og:image" content="https://www.classroom-aid.jp/social-media/og-image-classroomaid.png" />
    </>
  } else {
    return <>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={appName} />
      <meta property="og:description" content={appName} />
      <meta property="og:site_name" content={appName} />
      <meta property="og:url" content="https://www.printhub.jp" />
      <meta property="og:image" content="https://www.printhub.jp/social-media/printhub-ogimage.png" />
    </>
  }
}
