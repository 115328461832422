import { FC, ReactNode } from 'react';
import { getTenant } from 'src/lib/tenant';

export const Favicon: FC = (props) => {
  const tenantName = getTenant()

  if (tenantName == "classroomaid") {
    // 2024年のファビコンを極める: 本当に必要なファイルはほぼ6つ
    // https://techracho.bpsinc.jp/hachi8833/2024_02_09/108697
    return <>
      <link rel="icon" href="/classroomaid-tp.ico" sizes="32x32" />
      <link rel="icon" href="/classroomaid-tp.ico" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon-classroomaid.png" />
    </>
  } else if(tenantName == "zettalinx"){
    return <>
      <link rel="icon" type="image/png" sizes="32x32" href="/icons/abc-favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/icons/abc-favicon-192x192.png" />
      <link rel="apple-touch-icon" type="image/png" sizes="192x192" href="/icons/abc-favicon-180x180.png" />
      <link rel="shortcut icon" href="/abc-favicon.ico" />
    </>
  } else {
    return <>
      <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
      <link rel="shortcut icon" href="/favicon.ico" />
    </>
  }
}
