import { Resource } from "src/types/resource";
import { useEffect, useState } from "react"
import { nodeModelToResource } from "src/lib/json-converter"
import { Course } from 'src/types/googleClassroom';
import { NodeModel } from "../components/tree-view/types";
import { useDomainData } from "../contexts/domain-data-context"

// https://www.techighness.com/post/javascript-flatten-deeply-nested-array-of-objects-into-single-level-array/
const getMembers = (members:Resource[]) => {
  if (!members) {
    return []
  }
  let children = [];

  return members.map(m => {
    if (m.children && m.children.length) {
      children = [...children, ...m.children];
    }
    return m;
  }).concat(children.length ? getMembers(children) : children);
};

export const getAllCourseIdsUnderAResource = (resourceId:string | number | (string | number)[], resources:Resource[]) => {
  const allFlatResources = getMembers(resources)
  const theResource = allFlatResources.find((resource) => resource.id == resourceId)
  const theFlatResource = getMembers(theResource.children)
  const ids = theFlatResource.filter((resource) => !resource.children).map((resource)=> String(resource.id))
  return ids
}

const localHavePermissionOnChildCourses = (resourceId:string, resources:Resource[], courses: Course[]) => {
  // 配下のクラスに対する権限チェック(全てのcourseに対してteacherとして参加しているかチェック)
  const childrenCourseIds = getAllCourseIdsUnderAResource(resourceId, resources)
  return childrenCourseIds.every((courseId) => myTeachingCourseIds(courses).includes(courseId))
}

const getCourseIdsOnThisDomainWithoutPermission = (nodeModels:NodeModel[], courses: Course[]) => { // domainで誰かが所有しているけど、自分は所有してないcourseのid
  // https://zenn.dev/nananaoto/articles/3c49bcf18017b472b9ff#%E5%B7%AE%E9%9B%86%E5%90%88(difference)
  return courseIdsOnThisDomain(nodeModels).filter((val) => !myTeachingCourseIds(courses).includes(val))
}

const getInvalidResourceIds = (nodeModels:NodeModel[], resources:Resource[], courses: Course[]) => {
  return getCourseIdsOnThisDomainWithoutPermission(nodeModels, courses).concat(groupIdsOnThisDomainWithoutPermission(nodeModels, resources, courses))
}

const myTeachingCourseIds = (courses: Course[]) => {
  return courses.map(course => course.id)
}

const courseIdsOnThisDomain = (nodeModels:NodeModel[]) => {
  return nodeModels.filter((nodeModel) => !nodeModel.droppable).map((nodeModel) => String(nodeModel.id))
}

const groupIdsOnThisDomain = (nodeModels:NodeModel[]) => {
  return nodeModels.filter((nodeModel) => nodeModel.droppable).map((nodeModel) => String(nodeModel.id))
}

const groupIdsOnThisDomainWithoutPermission = (nodeModels:NodeModel[], resources:Resource[], courses: Course[]) => {
  return groupIdsOnThisDomain(nodeModels).filter(groupId => !localHavePermissionOnChildCourses(groupId, resources, courses))
}

export interface ResourcesContextValue {
  invalidResourceIds: string[];
  groupEventResourceIds: string[];
  courseIdsOnThisDomainWithoutPermission: string[];
  myResources: Resource[];
  havePermissionOnChildCourses: (resourceId: string) => boolean;
  nodeModelIsLoading: boolean
}

export const useResources = ():ResourcesContextValue => {
  const [invalidResourceIds, setInvalidResourceIds] = useState<string[]>([])
  const [groupEventResourceIds, setGroupEventResourceIds] = useState<string[]>([])
  const [courseIdsOnThisDomainWithoutPermission, setCourseIdsOnThisDomainWithoutPermission] = useState<string[]>([])
  const [myResources, setMyResources] = useState<Resource[]>([])
  const {
    courses,
    nodeModels,
    nodeModelIsLoading
  } = useDomainData()

  const check = (array:any[] | null | undefined) => {
    return array && array.length > 0
  }

  const havePermissionOnChildCourses = (resourceId:string) => {
    if (check(myResources) && check(courses)) {
      localHavePermissionOnChildCourses(resourceId, myResources, courses)
    } else {
      return false
    }
  }

  useEffect(() => {
    if(check(nodeModels)){
      const resourcesData = nodeModelToResource(nodeModels)
      const expandedResourcesData = resourcesData.map((data) => {
        let newData:Resource = data
        // https://fullcalendar.io/docs/resources-array
        newData.title = data.name // fullCalendarのresourceでは行に表示されるのはnameじゃなくてtitleなので追加(
        if (data.children) {
          newData.children = data.children.map((childData) => {
            let newChildData:Resource = childData
            newChildData.title = childData.name
            return newChildData
          })
        }
        return newData
      })
      setMyResources(expandedResourcesData)    
    }
  }, [nodeModels])

  useEffect(() => {
    if(check(nodeModels)){
      const ids = nodeModels.filter(nodeModel => nodeModel.droppable).map(nodeModel => String(nodeModel.id))
      setGroupEventResourceIds(ids)
    }
  }, [nodeModels])

  useEffect(() => {
    if(check(nodeModels) && check(myResources) && check(courses)) {
      const resourceIds = getInvalidResourceIds(nodeModels, myResources, courses)
      setInvalidResourceIds(resourceIds)
    }
  }, [myResources, courses])

  useEffect(() => {
    if(check(nodeModels) && check(courses)) {
      const ids = getCourseIdsOnThisDomainWithoutPermission(nodeModels, courses)
      setCourseIdsOnThisDomainWithoutPermission(ids)
    }
  }, [nodeModels, courses])

  return {
    invalidResourceIds,
    groupEventResourceIds,
    courseIdsOnThisDomainWithoutPermission,
    myResources,
    havePermissionOnChildCourses,
    nodeModelIsLoading,
  }
}
