import { Course } from "src/types/googleClassroom";
import { NodeModel } from "src/components/tree-view/types";
import { GoogleSpreadsheetClient } from "./google-spreadsheet-client";
import { NodeModel as NodeModelClass } from 'src/components/tree-view/node-model';

export const getNodeModelsWithSheets = async (sheetClient: GoogleSpreadsheetClient) => {
  // const rows = await sheetClient.nodeModelsSheet.getRows()
  const nodeModels = await sheetClient.nodeModels()
  return nodeModels
  // nodeModelにしか存在しないcourseを計算する
  // ここはなにやっているのか？courseとマージはあとで構わないなら、nodeModelの取得がcourseに依存しなくなるので、batchリクエストと並行して進められる
  // 初期読み込み時じゃなくてあとから動かすでもよさそう
  // 「classroom API側でcourseを削除した場合に、nodeModelからも削除されて、結果一覧に表示されないようにする」
  // https://github.com/sakawateam/printhub-front/commit/872861184aac9fc5b61eda094c3ba747d663954d
  // const coursesOnlyOnNodeModels = getNodeModelsNotExistOnCourses(nodeModels, courses)
  // await Promise.all(coursesOnlyOnNodeModels.map(async (nodeModel) => {
  //   await softDeleteNodeModel(domain, String(nodeModel.id))
  // }))
  // return mergeCoursesToNodeModel(courses, nodeModels)
}

export function getNodeModelsNotExistOnCourses(nodeModels:NodeModelClass[], courses:Course[]) {
  return nodeModels.filter(nodeModel => !courses.map(course => course.id).includes(String(nodeModel.id)) && !nodeModel.droppable);
}

export function getCoursesNotExistOnNodeModels(nodeModels:NodeModel[], courses:Course[]) {
  return courses.filter(course => !nodeModels.map(nodeModel => nodeModel.id).includes(Number(course.id)))
}

// クラス名にdiffがあるnodeModelだけ、textを書き換えて返す
export function getNodeModelsWithCourseNameDiffAssigned(nodeModels:NodeModelClass[], courses:Course[]) {
  return nodeModels.map((nodeModel) => {
    const correspondingCourse = courses.find(course => course.id === String(nodeModel.id))
    if(correspondingCourse){
      if(correspondingCourse.name !== nodeModel.text){
        nodeModel.text = correspondingCourse.name
        return nodeModel
      }
    }
    return null
  }).filter(Boolean)
}
