import { Event, UserContextData } from "src/lib/event"
import { BatchRequests } from "src/lib//classroom-api-client"
import { CourseWorkEventListFactory } from "./course-work-event-list-factory"
import { CourseWorkMaterialEventListFactory } from "./course-work-material-event-list-factory"
import { GroupEventListFactory } from "./group-event-list-factory"

export class EventListFactory {
  static async list(courseWorkRequests: BatchRequests[], courseWorkMaterialRequests: BatchRequests[]) {
    const courseWorkFactory = new CourseWorkEventListFactory()
    const courseWorkMaterialFactory = new CourseWorkMaterialEventListFactory()
    const courseWorkEvents:Event[] =  await courseWorkFactory.list(courseWorkRequests)
    const courseWorkMaterialEvents:Event[] =  await courseWorkMaterialFactory.list(courseWorkMaterialRequests)
    const courseWorkLikeEvents = (courseWorkEvents as Event[]).concat(courseWorkMaterialEvents as Event[])
    return courseWorkLikeEvents // courseWorkLikeEventsをとりあえず返して、あとでマージする
  }

  static async mergeWithGroupEvents(courseWorkLikeEvents: Event[], groupEventSheetClient:any) {
    const groupFactory = new GroupEventListFactory(groupEventSheetClient)
    const groupEvents:Event[] =  await groupFactory.list(courseWorkLikeEvents)
    const events = (courseWorkLikeEvents as Event[]).concat(groupEvents as Event[])
    return events
  }
}
