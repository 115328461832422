import { courseWorkToType } from "../type-converter";
import { DraggableTabTypes } from "src/types/file-drop-zone";
import { Kadai } from "./kadai";

export class Henkyaku extends Kadai {
  constructor() {
    super()
  }

  color() {
    return "#56ca70"
  }
  start() {
    return new Date() // courseWorkのscheduledDateは未設定になっている
  }
  end() {
    return this.courseWork.generateEnd()
  }
  type():DraggableTabTypes {
    return courseWorkToType(this.courseWork)
  }
}

